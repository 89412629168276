import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { P, Text } from 'stile-shared/src/components/2020/common/Primitives';
import { Column } from 'stile-shared/src/components/2020/layout/Column';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { CtaBlock } from 'components/blocks/CtaBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="Security"
      description="Keeping your data safe and private is paramount."
      slug={props.location.pathname}
    />
  );
}

function SecurityPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <HeroBlock
        title="Security"
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/security/cover_security.png"
            alt="Close-up of a person using a tablet device"
          />
        }
      />

      <TextBlock space={theme.space.m}>
        <Column>
          <P>
            Keeping your data safe and private is at the heart of Stile — from both a cultural as
            well as a technical perspective.
          </P>
          <P>
            We realise that the information we store on your behalf can be of a personal and
            sensitive nature, and because we don’t look at it, we simply assume it is all sensitive
            and act accordingly.
          </P>
        </Column>

        <Column>
          <Text as="h2" bold>
            Private by default
          </Text>
          <P>
            Every student, teacher and parent that uses Stile has their own unique username and
            password. Only users you specifically nominate can gain access to your school’s data.
          </P>
          <P>
            For every lesson a teacher creates and every piece of work a student submits, we manage
            a set of sophisticated security rules that specify exactly who can view and modify them.
          </P>
        </Column>

        <Column>
          <Text as="h2" bold>
            Securely hosted
          </Text>
          <P>
            Stile is hosted with Amazon Web Services (AWS), who are experts at providing reliable
            and secure server infrastructure. AWS are responsible for the physical security of the
            infrastructure that runs Stile.
          </P>
          <P>
            Australian Customers: <em>We exclusively store all your data in Australia</em> at AWS’s
            Sydney facility. AWS in Sydney is independently certified to meet the security
            requirements for unclassified Australian Government data as specified by Australian
            Signals Directorate’s (ASD){' '}
            <Link url="https://www.cyber.gov.au/ism" openInNewTab>
              Information Security Manual
            </Link>
            .
          </P>
        </Column>

        <Column>
          <Text as="h2" bold>
            Secure platform
          </Text>
          <P>
            {' '}
            We’ve built Stile from the ground up to with the privacy and security requirements of
            schools in mind. Stile’s development processes are designed to verify that security has
            been properly considered whenever we add new features or modify existing ones. We use
            automated systems to randomly audit our security policies, ensuring they are correctly
            enforced. Internal and third party reviews of our security practices are routinely
            performed to verify our security practices and identify potential vunerabilities.{' '}
          </P>

          <P>Access to Stile is only available via a secure connection (HTTPS).</P>
        </Column>

        <Column>
          <Text as="h2" bold>
            Controlled staff access
          </Text>
          <P>
            To provide you with helpful and timely support, a limited number of our staff have
            access to your data on an as-needed basis. Stile staff only access and view your data
            when you have specifically given us permission, usually in connection with a support
            enquiry. All such access is logged and regularly audited.
          </P>
          <P>
            All staff at Stile are required to hold a valid{' '}
            <Link url="https://www.workingwithchildren.vic.gov.au/" openInNewTab>
              Australian Working with Children
            </Link>{' '}
            check.
          </P>
        </Column>

        <Column>
          <Text as="h2" bold>
            Security culture
          </Text>
          <P>
            We practice what we preach. Our company polices require all employees to adhere to
            best-practice personal security guidelines that cover device security, encrypted file
            storage, password policies, social engineering and more.
          </P>
        </Column>

        <Column>
          <Text as="h2" bold>
            Australian Privacy Principles (APPs)
          </Text>
          <P>
            Stile complies with the Australian Privacy Principles. Please{' '}
            <Link url="/other/privacy/" openInNewTab>
              click here
            </Link>{' '}
            to view our full privacy policy.
          </P>
        </Column>

        <Column>
          <Text as="h2" bold>
            Want to know more?
          </Text>
          <P>
            Security and privacy are shared resposibilities. We work closely with our customers to
            ensure their security needs are met. If you would like more information on our security
            practices, please <Link url="/who-we-are/contact/">contact us</Link>. You can also check
            out{' '}
            <Link url="https://stileapp.com/go/security-guide" openInNewTab>
              our security guide for schools
            </Link>
            .
          </P>
        </Column>
      </TextBlock>

      <CtaBlock />
    </PageLayout>
  );
}

export default SecurityPage;
